<template>
  <v-form ref="form">
    <v-layout column class="mt-4">
      <v-layout align-center class="mx-4">
        <v-flex shrink>
          <v-switch
            v-model="p1Data.active"
            class="zg-text-control mt-0 pt-0"
            color="expressive_green"
            label="Wysyłaj moje dane do systemu P1"
            hide-details
            :ripple="false"
            inset
          ></v-switch>
        </v-flex>
        <InfoButton
          text="<center><b>Uwaga! Wyłączając tę opcję dane nie będą przesyłane do systemu P1!</b></center><br>
                Jeśli jesteś zawodem medycznym zgodnie z Rozporządzeniem MZ z dnia 26 czerwca 2020 r. 
                w sprawie szczegółowego zakresu danych zdarzenia medycznego przetwarzanego w systemie 
                informacji oraz sposobu i terminów przekazywania tych danych do Systemu Informacji 
                Medycznej masz obowiązek od 1 lipca 2021 roku przesyłania danych o świadczeniach 
                zdrowotnych udzielanych pacjentom do systemu P1."
        ></InfoButton>
      </v-layout>
      <v-layout column v-if="p1Data.active">
        <v-layout
          :class="[$vuetify.breakpoint.xsOnly && 'wrap', 'mt-5']"
          align-center
        >
          <v-flex :class="['xs12 sm6', $vuetify.breakpoint.xsOnly && 'mt-2']">
            <LabelTextField
              :value.sync="p1Data.first_name"
              :rules="textRules"
              label="Imię"
              required
            >
              <InfoButton
                slot="header-items"
                text="Imię musi być zgodne z imieniem w CWPM lub CWUB."
              ></InfoButton>
            </LabelTextField>
          </v-flex>
          <v-flex :class="['xs12 sm6']">
            <LabelTextField
              :value.sync="p1Data.last_name"
              :rules="textRules"
              label="Nazwisko"
              placeholder="Wpisz nazwisko zgodne z danymi w CWPM lub CWUB..."
              required
            >
              <InfoButton
                slot="header-items"
                text="Nazwisko musi być zgodne z nazwiskiem w CWPM lub CWUB."
              ></InfoButton>
            </LabelTextField>
          </v-flex>
        </v-layout>
        <v-layout :class="$vuetify.breakpoint.xsOnly && 'wrap'">
          <v-flex :class="['xs12 sm6 mb-1']">
            <P1ProfessionGroupSelect
              :value.sync="p1Data.profession_group"
              infoText="Wybierz swoją grupę zawodową np. fizjoterapeuta."
              required
            ></P1ProfessionGroupSelect>
          </v-flex>
          <v-flex
            shrink
            :class="['xs12 sm6', $vuetify.breakpoint.xsOnly && 'mt-6']"
          >
            <P1ProfessionRoleSelect
              :value.sync="p1Data.profession_role"
              infoText="Wybierz rolę jaką pełnisz."
              required
            ></P1ProfessionRoleSelect>
          </v-flex>
        </v-layout>
        <v-layout
          v-if="isNPWZVisible"
          class="mt-6"
          :class="$vuetify.breakpoint.xsOnly && 'wrap'"
          align-center
        >
          <v-flex :class="['xs12 sm6', $vuetify.breakpoint.xsOnly && 'mt-1']">
            <LabelTextField
              :value.sync="p1Data.profession_number"
              label="Numer prawa wykonywania zawodu"
              placeholder="Wpisz numer prawa wykonywania zawodu..."
              :rules="requiredProfessionNumber"
              required
            >
              <InfoButton
                slot="header-items"
                text="Wpisz swój numer prawa wykonywania zawodu. Możesz go znaleźć tutaj: <a href='https://kif.info.pl/rejestr/' target='blank'>https://kif.info.pl/rejestr/</a>"
              ></InfoButton>
            </LabelTextField>
          </v-flex>
        </v-layout>
        <v-layout
          v-if="!isNPWZVisible && p1Data.profession_group"
          class="mt-6"
          :class="$vuetify.breakpoint.xsOnly && 'wrap'"
          align-center
        >
          <v-layout :class="['xs12 sm6', $vuetify.breakpoint.xsOnly && 'mt-1']">
            <P1IDDocumentTypeSelect
              :value.sync="p1Data.id_type"
              hideOptional
              required
            ></P1IDDocumentTypeSelect>
          </v-layout>
          <v-layout :class="['xs12 sm6', $vuetify.breakpoint.xsOnly && 'mt-1']">
            <LabelTextField
              :value.sync="p1Data.id_number"
              label="Numer dokumentu"
              placeholder="Wpisz numer dokumentu..."
              :rules="textRules"
              required
            >
            </LabelTextField>
          </v-layout>
        </v-layout>
        <v-layout
          v-if="!isNPWZVisible && p1Data.profession_group"
          :class="$vuetify.breakpoint.xsOnly && 'wrap'"
          align-center
        >
          <v-flex :class="['xs12 sm6', $vuetify.breakpoint.xsOnly && 'mt-1']">
            <CountrySelect :value.sync="p1Data.country" required></CountrySelect>
          </v-flex>
        </v-layout>
      </v-layout>
    </v-layout>
  </v-form>
</template>

<script>
import Validators from "@/mixins/Validators";

export default {
  mixins: [Validators],
  props: {
    p1Data: {},
  },
  components: {
    InfoButton: () => import("@/components/buttons/InfoButton"),
    LabelTextField: () => import("@/components/LabelTextField"),
    P1ProfessionRoleSelect: () =>
      import("@/components/selects/p1/P1ProfessionRoleSelect"),
    P1ProfessionGroupSelect: () =>
      import("@/components/selects/p1/P1ProfessionGroupSelect"),
    P1IDDocumentTypeSelect: () =>
      import("@/components/selects/p1/P1IDDocumentTypeSelect"),
    CountrySelect: () => import("@/components/selects/CountrySelect"),
  },
  computed: {
    isNPWZVisible() {
      return (
        this.p1Data &&
        [4, 5, 6, 11, 12, 18, 19, 22].includes(this.p1Data.profession_group)
      );
    },
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
  },
};
</script>

<style scoped>
.zg-text-control::v-deep label {
  color: rgba(0, 0, 0, 0.87);
}
</style>