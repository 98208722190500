<script>
import { validatePolish } from "validate-polish";

export default {
  data() {
    return {
      textRules: [(v) => !!v || "Pole nie może być puste"],
      emailRules: [
        (v) => !!v || "Adres e-mail nie może być pusty.",
        (v) =>
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "Niepoprawny adres e-mail.",
      ],
      nonRequiredEmailRules: [
        function (v) {
          if (v == undefined || v.length == 0) {
            return true;
          } else {
            return (
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                v
              ) || "Niepoprawny adres e-mail."
            );
          }
        },
      ],
      nonRequiredPhoneRules: [
        function (v) {
          if (v == undefined || v.length == 0) {
            return true;
          } else {
            return /^[\s0-9+]{8,15}$/.test(v) || "Niepoprawny numer telefonu.";
          }
        },
      ],
      requiredPhoneRules: [
        function (v) {
          if (v == undefined || v.length == 0) {
            return "Numer telefonu nie może być pusty";
          } else {
            return /^[\s0-9+]{8,15}$/.test(v) || "Niepoprawny numer telefonu.";
          }
        },
      ],
      passwordRules: [
        (v) => !!v || "Hasło nie może być puste.",
        (v) => v.length >= 8 || "Hasło musi mieć przynajmniej 8 znaków.",
      ],
      requiredPostCodeRules: [
        function (v) {
          return /^[0-9]{5}$/.test(v) || "Niepoprawny kod pocztowy.";
        },
      ],
      nonRequiredPostCodeRules: [
        function (v) {
          if (v == undefined || v.length == 0) {
            return true;
          } else {
            return /^[0-9]{5}$/.test(v) || "Niepoprawny kod pocztowy.";
          }
        },
      ],
      nonRequiredPESEL: [
        function (pesel) {
          if (pesel == undefined || pesel.length == 0) return true;
          return validatePolish.pesel(pesel) || "Niepoprawny numer PESEL.";
        },
      ],
      nonRequiredDate: [
        function (v) {
          if (v == undefined || v.length == 0) {
            return true;
          } else {
            return (
              // eslint-disable-next-line no-useless-escape
              /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/.test(v) ||
              "Niepoprawna data."
            );
          }
        },
      ],
      requiredPrice: [
        function (v) {
          if (v == undefined || v.length == 0) {
            return "Cena nie może być pusta.";
          } else {
            return /^(\d+[.,]?(\d{1,2})?)$/.test(v) || "Niepoprawna cena.";
          }
        },
      ],
      nonRequiredPrice: [
        function (v) {
          if (v == undefined || v.length == 0) {
            return true;
          } else {
            return /^(\d+[.,]?(\d{1,2})?)$/.test(v) || "Niepoprawna cena.";
          }
        },
      ],
      requiredProfessionNumber: [
        function (v) {
          if (v == undefined || v.length == 0) {
            return "Pole nie może być puste";
          } else {
            return (
              /^[0-9]+$/.test(v) || "Niepoprawny numer wykonywania zawodu."
            );
          }
        },
      ],
      nonRequiredProfessionNumber: [
        function (v) {
          if (v == undefined || v.length == 0) {
            return true;
          } else {
            return (
              /^[0-9]+$/.test(v) || "Niepoprawny numer wykonywania zawodu."
            );
          }
        },
      ],
      requiredRPWDL: [
        function (v) {
          if (v == undefined || v.length == 0) {
            return "Pole nie może być puste";
          } else {
            return /^[0-9]+$/.test(v) || "Niepoprawny numer RPWDL.";
          }
        },
      ],
      nonRequiredRPWDL: [
        function (v) {
          if (v == undefined || v.length == 0) {
            return true;
          } else {
            return /^[0-9]+$/.test(v) || "Niepoprawny numer RPWDL.";
          }
        },
      ],
      requiredInteger: [
        function (v) {
          if (v == undefined || v.length == 0) {
            return "Pole nie może być puste.";
          } else {
            return /^[0-9]+$/.test(v) || "Niepoprawna wartość.";
          }
        },
      ],
      requiredNIP: [
        function (nip) {
          return validatePolish.nip(nip) || "Niepoprawny NIP.";
        },
      ],
      requiredREGON: [
        function (regon) {
          return validatePolish.regon(regon) || "Niepoprawny REGON.";
        },
      ],
      roomName: [
        function (text) {
          return (
            /^[A-ZŻŹĆĄŚĘŁÓŃ0-9]{1,4}$/.test(text) ||
            "Nazwa musi posiadać tylko duże litery lub duże litery z cyframi lub same cyfry"
          );
        },
      ],
      requiredP1: [
        function (v) {
          if (v == undefined || v.length == 0) {
            return "Pole nie może być puste";
          } else {
            return (
              /^[0-9]+\.[0-9]+\.[0-9]+\.[0-9]+\.[0-9]+\.[0-9]+\.[0-9]+\.[0-9]+\.[0-9]+\.[0-9]+$/.test(
                v
              ) || "Niepoprawny identyfikator P1."
            );
          }
        },
      ],
    };
  },
};
</script>
